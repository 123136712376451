











































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {mdiCalendar, mdiClock, mdiClose} from '@mdi/js';
import {Prop} from 'vue-property-decorator';

@Component
export default class DateTimeRangeQuestion extends Vue {
  @Prop({default: ''}) dateStart!: string;
  @Prop({default: ''}) dateEnd!: string;
  @Prop({default: ''}) timeStart!: string;
  @Prop({default: ''}) timeEnd!: string;
  private iconTime = mdiClock;
  private iconCalendar = mdiCalendar;
  private iconClear = mdiClose;
  private menuDateStart = false;
  private menuDateEnd = false;
  private menuTimeStart = false;
  private menuTimeEnd = false;
}
