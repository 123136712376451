




























import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {SurveyFieldItemResourcePartial} from '@/interfaces';
import {mdiAsterisk} from '@mdi/js';

@Component
export default class RadioGroup extends Vue {
  @Prop({default: false}) private disabled!: boolean;
  @Prop({default: []}) private items!: SurveyFieldItemResourcePartial[];
  @Prop() private label!: string;
  @Prop({default: false}) private readOnly!: boolean;
  @Prop() private name!: string;
  @Prop() private placeholder!: string;
  @Prop() private hint!: string;
  @Prop({default: false}) private required!: boolean;
  @Prop() private value!: string;
  @Prop({default: false}) private persistentHint!: boolean;
  private iconRequired = mdiAsterisk;
}
