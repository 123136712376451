















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {mdiRepeat} from '@mdi/js';

@Component
export default class IconQuestionCollectionRepeatable extends Vue {
  @Prop({default: false}) private show!: boolean;
  private iconRepeatable = mdiRepeat;
}
