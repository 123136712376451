














import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component
export default class FileUploadAllowedTypesAutocomplete extends Vue {
  @Prop({default: () => []}) value!: string;

  private items = [
    'jpeg',
    'jpg',
    'pdf',
    'doc',
    'docx',
    'png',
    'gif',
    'xls',
    'xlsx',
    'zip',
  ];

  update(value: string): void {
    this.$emit('input', value);
  }
}
