








































































import Vue from 'vue';
import Component from 'vue-class-component';
import {AlertMessage, QuestionResource} from '@/interfaces';
import {Prop, Watch} from 'vue-property-decorator';
import {questionModule, surveyFieldModule} from '@/store/modules';
import SurveyFieldTypeAutocomplete from '@/components/surveys-edit/SurveyFieldTypeAutocomplete.vue';
import Dialog from '@/components/ui/Dialog.vue';
import DialogAlert from '@/components/ui/DialogAlert.vue';
import RequiredLabel from '@/components/ui/RequiredLabel.vue';
import QuestionCollectionAutocomplete from '@/components/surveys-edit/QuestionCollectionAutocomplete.vue';
import ButtonEditAction from '@/components/ui/ButtonEditAction.vue';
import ButtonDeleteAction from '@/components/ui/ButtonDeleteAction.vue';
import SurveyField from '@/components/surveys/SurveyField.vue';
import {QuestionType} from '@/enums';

@Component({
  components: {
    SurveyField,
    ButtonDeleteAction,
    ButtonEditAction,
    QuestionCollectionAutocomplete,
    RequiredLabel,
    DialogAlert,
    Dialog,
    SurveyFieldTypeAutocomplete,
  },
})
export default class QuestionCollectionAddToSurveyDialog extends Vue {
  @Prop() fieldId!: string;
  @Prop({default: false}) newItem!: false;
  @Prop() surveyPageId!: number;
  @Prop() surveyId!: number;
  private alert: AlertMessage | null = null;
  private questionCollectionId: string | null = null;
  private valid = false;
  private userQuestionResponses: Record<
    string,
    string | Record<string, string>
  > = {};

  get busy() {
    return surveyFieldModule.busy;
  }

  get deleting() {
    return surveyFieldModule.loading;
  }

  get loading() {
    return surveyFieldModule.loading;
  }

  get saving() {
    return surveyFieldModule.saving;
  }

  get listing(): boolean {
    return questionModule.listing;
  }

  get surveyQuestions(): QuestionResource[] {
    return questionModule.list.filter(
      item =>
        item.attributes.questionCollectionId ===
          parseInt(String(this.questionCollectionId)) &&
        item.attributes.surveyQuestion
    );
  }

  get userQuestions(): QuestionResource[] {
    if (!this.questionCollectionId) {
      return [];
    }
    return questionModule.list.filter(
      item =>
        item.attributes.questionCollectionId ===
          parseInt(String(this.questionCollectionId)) &&
        !item.attributes.surveyQuestion
    );
  }

  async save(): Promise<void> {
    try {
      const userQuestionValues: Record<string, string> = {};
      Object.keys(this.userQuestionResponses).map(key => {
        if (typeof this.userQuestionResponses[key] === 'string') {
          userQuestionValues[key] = this.userQuestionResponses[key] as string;
        } else {
          userQuestionValues[key] = (this.userQuestionResponses[key] as Record<
            string,
            string
          >).value;
        }
        return;
      });

      await surveyFieldModule.action({
        attributes: {
          action: 'add-question-collection',
          surveyId: String(this.surveyId),
          surveyPageId: String(this.surveyPageId),
          questionCollectionId: String(this.questionCollectionId),
          userQuestionValues: userQuestionValues,
        },
      });

      //this.snackbar.visible = true;
      //this.snackbar.message = 'Restoring question collection order saved.';
      //await surveyFieldModule.reset();
      this.$emit('close', 'Field saved successfully');
    } catch (err) {
      this.alert = {message: 'Could not save field', type: 'error'};
    }
  }

  async cancel() {
    this.$emit('close');
  }

  async loadQuestions() {
    // don't bother trying to load questions if a question collection has not been set yet
    if (!this.questionCollectionId) {
      return;
    }
    await questionModule.loadMulti({
      filters: {questionCollectionId: this.questionCollectionId},
    });
  }

  @Watch('questionCollectionId')
  async onChildChanged(val: string, oldVal: string) {
    console.log(val, oldVal);
    await this.loadQuestions();
  }
}
