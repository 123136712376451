











































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {mdiAsterisk} from '@mdi/js';
import RequiredLabel from '@/components/ui/RequiredLabel.vue';
import {InputValidationRules} from 'vuetify';
@Component({
  components: {RequiredLabel},
})
export default class RatingRadio extends Vue {
  @Prop({default: false}) private disabled!: boolean;
  @Prop() private label!: string;
  @Prop({default: false}) private readOnly!: boolean;
  @Prop() private name!: string;
  @Prop() private placeholder!: string;
  @Prop() private hint!: string;
  @Prop({default: false}) private required!: boolean;
  @Prop() private value!: string;
  @Prop({default: false}) private persistentHint!: boolean;
  @Prop({default: []}) private rules!: InputValidationRules;
  private iconRequired = mdiAsterisk;
}
