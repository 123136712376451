















































































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {SurveyType as SurveyTypeEnum} from '@/enums';

@Component
export default class SurveyType extends Vue {
  @Prop() private type!: SurveyTypeEnum;
  @Prop({default: false}) private chip!: boolean;
  private surveyType = SurveyTypeEnum;
}
