import {Module} from 'vuex-class-modules';
import store from '@/store';
import {surveyFieldItemApi as api} from '@/api';
import {
  SurveyFieldItemAttributes as Attributes,
  SurveyFieldItemCreate as Create,
  SurveyFieldItemResource as Resource,
} from '@/interfaces';
import {ResourceType} from '@/enums';
import {CrudModuleAbstract} from './CrudModuleAbstract';

const defaultItem = (): Resource => {
  return {
    id: 'new',
    type: ResourceType.SurveyFieldItem,
    attributes: {
      id: 0,
      label: '',
      surveyFieldId: 0,
      value: '',
      userCreated: {id: 0, firstName: '', lastName: ''},
      userModified: {id: 0, firstName: '', lastName: ''},
      created: new Date(),
      modified: new Date(),
    },
  };
};

@Module
class SurveyFieldItemModule extends CrudModuleAbstract<
  Resource,
  Attributes,
  Create
> {}

export const surveyFieldItemModule = new SurveyFieldItemModule(
  {
    store,
    name: ResourceType.SurveyFieldItem,
  },
  () => api,
  defaultItem
);
