





































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {mdiAsterisk} from '@mdi/js';
import {QuestionType} from '@/enums';
import {FieldValidation, SelectItem} from '@/interfaces';
import CheckboxGroup from '@/components/surveys/CheckboxGroup.vue';
import DatePicker from '@/components/surveys/DatePicker.vue';
import DateTimePicker from '@/components/surveys/DateTimePicker.vue';
import RadioGroup from '@/components/surveys/RadioGroup.vue';
import DropdownChain from '@/components/surveys/DropdownChain.vue';
import {rulesEmail, rulesRequired} from '@/utility';
import {InputValidationRules} from 'vuetify';
import DisplayControl from '@/components/surveys/DisplayControl.vue';
import RatingRadio from '@/components/surveys/RatingRadio.vue';

@Component({
  components: {
    RatingRadio,
    DisplayControl,
    DropdownChain,
    RadioGroup,
    DateTimePicker,
    DatePicker,
    CheckboxGroup,
  },
})
export default class SurveyField extends Vue {
  @Prop({default: false}) private disabled!: boolean;
  @Prop({default: null}) private dropdownChainId!: null | string;
  @Prop({default: null}) private displayControlId!: null | string;
  @Prop() private helpText!: string;
  @Prop({default: []}) private items!: SelectItem[];
  @Prop() private label!: string;
  @Prop() private name!: string;
  @Prop() private placeholder!: string;
  @Prop({default: false}) private readOnly!: boolean;
  @Prop({default: false}) private persistentHint!: boolean;
  @Prop({default: false}) private sampleValue!: boolean;
  @Prop() private type!: string;
  @Prop({default: {}}) private validation!: FieldValidation;
  @Prop() private value!: string;
  private checked = false;
  private iconRequired = mdiAsterisk;
  private required = false;
  private questionType = QuestionType;
  private rules: InputValidationRules = [];

  get accept(): string {
    if (this.validation.allowedFileTypes === undefined) {
      return '';
    }
    return this.validation.allowedFileTypes.map(type => `.${type}`).join(',');
  }

  created() {
    this.checked = Boolean(this.sampleValue);

    if (this.validation.required) {
      this.required = true;
      this.rules = rulesRequired;
    }
    if (this.type === QuestionType.Email) {
      this.rules = rulesEmail;
    }
    if (
      this.type === QuestionType.File &&
      this.validation.allowedFileTypes !== undefined
    ) {
      // this.rules = [ruleEmail];
      // this.rules.push(
      //   v =>
      //     !this.validation.allowedFileTypes?.includes(v) ||
      //     'Invalid file type selected'
      // );
      //this.rules.push(v => false || 'Invalid file type selected');
    }
  }
}
