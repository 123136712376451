

























import Vue from 'vue';
import Component from 'vue-class-component';
import {SurveyResource} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import {surveyModule} from '@/store/modules';
import {isTargeted} from '@/utility';
import {Route} from '@/enums';

@Component({
  components: {},
})
export default class SurveyNavigation extends Vue {
  @Prop({default: null}) private surveyId!: string;
  private isTargeted = isTargeted;
  private routeSurveyRecipients = Route.SurveyRecipients;
  private routeSurveyInvitations = Route.SurveyInvitations;
  private routeSurveysEdit = Route.SurveysEdit;

  get getting(): boolean {
    return surveyModule.getting;
  }

  get saving(): boolean {
    return surveyModule.saving;
  }

  get survey(): SurveyResource | null {
    const survey = surveyModule.find(this.surveyId);
    if (!survey) {
      return null;
    }
    return survey;
  }

  async created(): Promise<void> {
    if (!this.survey) {
      try {
        await surveyModule.loadById(this.surveyId);
      } catch (err) {
        console.error(err.response.data);
      }
    }
  }
}
