














import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {SurveyFieldResource} from '@/interfaces';
import {mdiRepeat} from '@mdi/js';

@Component
export default class IconSurveyFieldRepeatable extends Vue {
  @Prop() private surveyField!: SurveyFieldResource;
  private iconRepeatable = mdiRepeat;
}
