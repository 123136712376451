





import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {VueEditor} from 'vue2-editor';

@Component({
  components: {
    VueEditor,
  },
})
export default class Wysiwyg extends Vue {
  @Prop() private title!: string;
  @Prop({default: false}) private saveDisabled!: boolean;
  @Prop({default: false}) private saveLoading!: boolean;
  @Prop({default: false}) private cancelDisabled!: boolean;
  @Prop({default: false}) private cancelLoading!: boolean;
  @Prop({default: false}) private loading!: boolean;
  @Prop({default: null}) private input!: () => {} | null;
  @Prop({default: false}) private value!: string;
  private content = '';
}
