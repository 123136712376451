


























































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  EditableFieldSaveEvent,
  SelectItem,
  Snackbar,
  SurveyResource,
  UserGroupResource,
} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import CreatedModifiedCell from '@/components/ui/CreatedModifiedCell.vue';
import {surveyModule, userGroupModule} from '@/store/modules';
import EditableField from '@/components/ui/EditableField.vue';
import SurveyType from '@/components/ui/SurveyType.vue';
import DatePicker from '@/components/surveys/DatePicker.vue';
import Wysiwyg from '@/components/ui/Wysiwyg.vue';
import ButtonSurveyPublish from '@/components/surveys-edit/ButtonSurveyPublish.vue';
import ButtonSurveyPreview from '@/components/surveys-edit/ButtonSurveyPreview.vue';
import {sleep} from '@/utility';
import NotificationBar from '@/components/ui/NotificationBar.vue';
import ButtonSurveyEmbed from '@/components/surveys-edit/ButtonSurveyEmbed.vue';
import ButtonSurveyMore from '@/components/surveys-edit/ButtonSurveyMore.vue';
import ButtonSurveySend from '@/components/surveys-edit/ButtonSurveySend.vue';
import ButtonSurveySendInvitations from '@/components/surveys-edit/ButtonSurveySendInvitations.vue';
import {isOpen, isTargeted} from '@/utility/surveyType';

@Component({
  components: {
    ButtonSurveySendInvitations,
    ButtonSurveySend,
    ButtonSurveyMore,
    ButtonSurveyEmbed,
    NotificationBar,
    ButtonSurveyPublish,
    ButtonSurveyPreview,
    Wysiwyg,
    DatePicker,
    SurveyType,
    EditableField,
    CreatedModifiedCell,
  },
})
export default class SurveyActions extends Vue {
  @Prop({default: null}) private survey!: SurveyResource;
  @Prop({default: null}) private surveyId!: string;
  private savingId = '';
  private publishing = false;
  private snackbar: Snackbar = {visible: false, message: ''};
  private isOpen = isOpen;
  private isTargeted = isTargeted;

  get getting(): boolean {
    return surveyModule.getting;
  }

  get saving(): boolean {
    return surveyModule.saving;
  }

  get loadingUserGroups(): boolean {
    return userGroupModule.loading;
  }

  get userGroups(): SelectItem[] {
    return userGroupModule.list.length > 0
      ? userGroupModule.list.map((userGroup: UserGroupResource) => {
          return {
            text: userGroup.attributes.label,
            value: userGroup.attributes.id,
          };
        })
      : [];
  }

  async created(): Promise<void> {
    if (!this.survey) {
      try {
        await surveyModule.loadById(this.surveyId);
      } catch (err) {
        console.error(err.response.data);
      }
    }

    try {
      await userGroupModule.loadMulti();
    } catch (err) {
      console.error(err.response.data);
    }
  }

  async createAction(action: string) {
    this.publishing = true;
    if (process.env.NODE_ENV === 'development') {
      await sleep(2000);
    }
    let state;
    switch (action) {
      case 'publish':
        state = 'published';
        break;
      case 'unpublish':
        state = 'unpublished';
        break;
      // case 'send':
      //   state = 'sent';
      //   break;
      // case 'resend':
      //   state = 'resent';
      //   break;
    }
    try {
      await surveyModule.action({
        attributes: {
          action: action,
          surveyId: this.survey.id,
        },
      });
      this.publishing = false;
      this.snackbar.message = `Your survey has been ${state}`;
      this.snackbar.visible = true;
      await surveyModule.loadById(this.survey.id);
    } catch (err) {
      this.publishing = false;
      console.error(err);
      const error = err.response?.data?.errors[0]?.detail;
      this.snackbar.message = `Error: ${error ? error : ''}`;
      this.snackbar.visible = true;
    }
  }

  async save({id}: EditableFieldSaveEvent) {
    // in this case the id is not a record id and just an identifier for the
    // loading indicator
    this.savingId = id;
    await surveyModule.save(this.survey.id);
  }
}
