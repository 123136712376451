






















































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {dropdownChainModule} from '@/store/modules';
import {SelectItem, SurveyFieldItemResourcePartial} from '@/interfaces';
import {mdiAsterisk} from '@mdi/js';

@Component
export default class DropdownChain extends Vue {
  @Prop({default: false}) private disabled!: boolean;
  @Prop({default: []}) private items!: SurveyFieldItemResourcePartial[];
  @Prop() private label!: string;
  @Prop({default: false}) private readOnly!: boolean;
  @Prop() private name!: string;
  @Prop() private placeholder!: string;
  @Prop() private hint!: string;
  @Prop({default: false}) private required!: boolean;
  @Prop() private value!: string;
  @Prop({default: false}) private persistentHint!: boolean;
  @Prop() id!: string;
  @Prop({default: null}) input!: () => {} | null;
  private primaryId = '';
  private secondaryId = '';
  private iconRequired = mdiAsterisk;

  get itemsPrimary(): SelectItem[] {
    return dropdownChainModule.list
      .filter(item => String(item.attributes.parentId) === String(this.id))
      .map(item => ({
        text: item.attributes.label,
        value: item.id,
      }));
  }

  get primaryItem() {
    if (!this.primaryId) {
      return null;
    }
    return dropdownChainModule.find(this.primaryId);
  }

  get itemsSecondary(): SelectItem[] {
    return dropdownChainModule.list
      .filter(
        item => String(item.attributes.parentId) === String(this.primaryId)
      )
      .map(item => ({
        text: item.attributes.label,
        value: item.attributes.name,
      }));
  }

  async created(): Promise<void> {
    await dropdownChainModule.loadMulti();
  }
}
