






















import Vue from 'vue';
import Component from 'vue-class-component';
import {AlertMessage, SurveyResource} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import {surveyModule} from '@/store/modules';
import DialogAlert from '@/components/ui/DialogAlert.vue';
import DialogActions from '@/components/ui/DialogActions.vue';
import Dialog from '@/components/ui/Dialog.vue';
import {Route} from '@/enums';

@Component({
  components: {
    Dialog,
    DialogActions,
    DialogAlert,
  },
})
export default class DeleteSurveyConfirmDialog extends Vue {
  @Prop() survey!: SurveyResource;
  private alert: AlertMessage | null = null;

  get busy(): boolean {
    return surveyModule.busy;
  }

  get deleting(): boolean {
    return surveyModule.deleting;
  }

  async cancel() {
    this.$emit('close');
  }

  async save(): Promise<void> {
    try {
      await surveyModule.delete(this.survey.id);

      this.alert = {
        type: 'success',
        message: 'Survey deleted successfully',
      };
      this.$emit('close', 'Survey deleted successfully');
      // surveyModule.reset();
      // surveyFieldModule.reset();
      // surveyFieldItemModule.reset();
      await this.$router.replace({
        name: Route.Surveys,
      });
    } catch (err) {
      console.error(err);
      const error = err.response?.data?.errors[0]?.detail;
      this.alert = {type: 'error', message: error ? error : 'Error'};
    }
  }
}
