






































































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {SurveyType} from '@/enums';
import {isTargeted, isOpen} from '@/utility';
import {Snackbar} from '@/interfaces';
import SendTargetedSurveyConfirmDialog from '@/components/surveys-edit/SendTargetedSurveyConfirmDialog.vue';
import NotificationBar from '@/components/ui/NotificationBar.vue';
import SendInvitationsConfirmDialog from '@/components/surveys-edit/SendInvitationsConfirmDialog.vue';
import {surveyRecipientModule} from '@/store/modules';
@Component({
  components: {
    SendInvitationsConfirmDialog,
    NotificationBar,
    SendTargetedSurveyConfirmDialog,
  },
})
export default class ButtonSurveySendInvitations extends Vue {
  @Prop() private type!: SurveyType;
  @Prop() private sent!: boolean;
  @Prop({default: false}) private disabled!: boolean;
  @Prop({default: false}) private loading!: boolean;
  @Prop({default: ''}) private tooltip!: string;
  @Prop() private surveyId!: string;
  private action = '';
  private confirmDialogShown = false;
  private isOpen = isOpen;
  private isTargeted = isTargeted;
  private snackbar: Snackbar = {visible: false, message: ''};

  get surveyRecipientsCount(): number {
    return surveyRecipientModule.list.length;
  }

  get surveyRecipientsLoading(): boolean {
    return surveyRecipientModule.listing;
  }

  async created(): Promise<void> {
    await surveyRecipientModule.loadMulti({filters: {surveyId: this.surveyId}});
  }

  closeConfirmDialog(message: string) {
    this.confirmDialogShown = false;
    if (message) {
      this.snackbar.visible = true;
      this.snackbar.message = message;
    }
  }

  openConfirmDialog(action: string) {
    this.action = action;
    this.confirmDialogShown = true;
  }
}
