























































































import Vue from 'vue';
import Component from 'vue-class-component';
import {mdiAsterisk, mdiCalendar, mdiClock, mdiClose} from '@mdi/js';
import {Prop} from 'vue-property-decorator';
import {parseISO} from 'date-fns';
import {format} from 'date-fns-tz';

@Component
export default class DateTimePicker extends Vue {
  @Prop({default: null}) value!: Date;
  @Prop({default: ''}) label!: string;
  @Prop({default: false}) required!: boolean;
  @Prop({default: null}) input!: (event: Date) => {} | null;
  private date: string | null = null;
  private time: string | null = null;

  private iconTime = mdiClock;
  private iconCalendar = mdiCalendar;
  private iconClear = mdiClose;
  private iconRequired = mdiAsterisk;
  private menuDate = false;
  private menuTime = false;

  created() {
    console.log(this.value);
    if (this.value) {
      this.date = format(this.value, 'yyyy-MM-dd', {
        timeZone: 'America/Toronto',
      });
      this.time = format(this.value, 'HH:mm:ss', {timeZone: 'America/Toronto'});
    }
  }

  updateDate(value: string) {
    this.date = value;
    this.update();
  }

  updateTime(value: string) {
    this.time = value;
    this.update();
  }

  update() {
    const date = parseISO(
      String(`${this.date ? this.date : ''} ${this.time ? this.time : ''}`)
    );
    console.log(date);
    //this.$emit('update:value', date);
    this.input(date);
  }
}
