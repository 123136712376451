











import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';

@Component
export default class ButtonSurveyPreview extends Vue {
  @Prop() private url!: string;
  @Prop({default: ''}) private tooltip!: string;

  public preview(): void {
    const url =
      this.url.indexOf('/key') === -1
        ? `${this.url}/preview`
        : this.url.replace('/key', '/preview');
    const openedWindow = window.open(url, '_blank');
    if (!openedWindow) {
      console.error('window is not defined');
      return;
    }
    openedWindow.focus();
  }
}
