






















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import RequiredLabel from '@/components/ui/RequiredLabel.vue';
import {displayControlModule, surveyModule} from '@/store/modules';
import {SelectItem, SurveyResource} from '@/interfaces';
import {InputValidationRules} from 'vuetify';
import {isTargeted} from '@/utility';

@Component({components: {RequiredLabel}})
export default class DisplayControlAutocomplete extends Vue {
  @Prop() value!: string;
  @Prop() surveyId!: string;
  @Prop({default: false}) required!: boolean;
  @Prop({default: () => []}) rules!: InputValidationRules;
  private isTargeted = isTargeted;

  get survey(): SurveyResource {
    return surveyModule.find(this.surveyId);
  }

  get list(): SelectItem[] {
    return displayControlModule.list
      .filter(
        item =>
          isTargeted(this.survey.attributes.type) ||
          (!isTargeted(this.survey.attributes.type) &&
            !item.attributes.targeted)
      )
      .map(item => ({
        text: item.attributes.controlName,
        value: item.id,
        hint: `Clear On Complete: ${
          item.attributes.clearOnComplete ? 'Yes' : 'No'
        } | Targeted: ${item.attributes.targeted ? 'Yes' : 'No'}`,
      }));
  }

  get listing(): boolean {
    return displayControlModule.listing;
  }

  async created(): Promise<void> {
    await displayControlModule.loadMulti();
  }

  update(value: string): void {
    this.$emit('input', value);
  }
}
