























import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {QuestionType} from '@/enums';
import RequiredLabel from '@/components/ui/RequiredLabel.vue';
import {InputValidationRules} from 'vuetify';

@Component({components: {RequiredLabel}})
export default class SurveyFieldTypeAutocomplete extends Vue {
  @Prop({default: false}) private readOnly!: false;
  @Prop({default: false}) private disabled!: false;
  @Prop({default: () => []}) private rules!: InputValidationRules;
  @Prop() private value!: string;
  private types: {text: string; value: QuestionType | null; hint?: string}[] = [
    {
      text: 'Short Text',
      value: QuestionType.Short,
      hint: 'One-line text box',
    },
    {
      text: 'Long Text',
      value: QuestionType.Long,
      hint: 'Multi-line text box',
    },
    {
      text: 'Date',
      value: QuestionType.Date,
    },
    {
      text: 'Date and Time',
      value: QuestionType.DateTime,
    },
    {
      text: 'Display Control',
      value: QuestionType.DisplayControl,
    },
    {
      text: 'Email',
      value: QuestionType.Email,
    },
    {
      text: 'Checkbox',
      value: QuestionType.Checkbox,
    },
    {
      text: 'Checkbox group',
      value: QuestionType.CheckboxGroup,
      hint: 'Multi-select',
    },
    {
      text: 'Dropdown',
      value: QuestionType.Dropdown,
    },
    {
      text: 'Dropdown Chain',
      value: QuestionType.DropdownChain,
      hint:
        'Two dropdown boxes with the contents in the second depending on the contents of the first',
    },
    {
      text: 'File Upload',
      value: QuestionType.File,
    },
    // {
    //   text: 'Hidden',
    //   value: QuestionType.Hidden,
    // },
    {
      text: 'Heading',
      value: QuestionType.Heading,
    },
    {
      text: 'Address Autocomplete',
      value: QuestionType.Address,
    },
    {
      text: 'Radio group',
      value: QuestionType.RadioGroup,
      hint: 'Single-select',
    },
    {
      text: 'Rating Radio',
      value: QuestionType.RatingRadio,
      hint: 'Allow the user to select a value between 0 and 10',
    },
  ];
  update(value: string): void {
    this.$emit('input', value);
  }
}
