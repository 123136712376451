





















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {UserResourcePartial} from '@/interfaces';
import {dateFormat, timeAgo} from '@/filters';

@Component
export default class CreatedModifiedCell extends Vue {
  @Prop() private createdDate!: Date;
  @Prop() private modifiedDate!: Date;
  @Prop() private createdUser!: UserResourcePartial;
  @Prop() private modifiedUser!: UserResourcePartial;
  private dateFormat = dateFormat;
  private timeAgo = timeAgo;

  get createdModified() {
    const created = this.timeAgo(this.createdDate);
    const modified = this.timeAgo(this.modifiedDate);
    if (created === modified) {
      return created;
    }
    return `${created} / ${modified}`;
  }
}
