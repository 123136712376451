









import Vue from 'vue';
import Component from 'vue-class-component';
import {mdiAsterisk} from '@mdi/js';
import {Prop} from 'vue-property-decorator';

@Component
export default class RequiredLabel extends Vue {
  @Prop() label!: string;
  iconRequired = mdiAsterisk;
}
