













































import Vue from 'vue';
import Component from 'vue-class-component';
import {mdiAsterisk, mdiCalendar, mdiClose} from '@mdi/js';
import {Prop} from 'vue-property-decorator';

@Component
export default class DatePicker extends Vue {
  @Prop({default: ''}) value!: string;
  @Prop({default: ''}) hint!: string;
  @Prop({default: 'Date'}) label!: string;
  @Prop({default: false}) required!: boolean;
  @Prop({default: null}) input!: () => {} | null;
  private iconCalendar = mdiCalendar;
  private iconClear = mdiClose;
  private iconRequired = mdiAsterisk;
  private menuDate = false;

  created() {
    //this.date = this.value;
  }
}
