































































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {mdiDotsVertical, mdiMenuDown} from '@mdi/js';
import {Route} from '@/enums';
import {Snackbar, SurveyResource} from '@/interfaces';
import SaveTemplateDialog from '@/components/surveys-edit/SaveTemplateDialog.vue';
import {isOpen} from '@/utility';
import SendInvitationsConfirmDialog from '@/components/surveys-edit/SendInvitationsConfirmDialog.vue';
import NotificationBar from '@/components/ui/NotificationBar.vue';
import DeleteSurveyConfirmDialog from '@/components/surveys-edit/DeleteSurveyConfirmDialog.vue';
@Component({
  components: {
    DeleteSurveyConfirmDialog,
    NotificationBar,
    SendInvitationsConfirmDialog,
    SaveTemplateDialog,
  },
})
export default class ButtonSurveyMore extends Vue {
  @Prop() private survey!: SurveyResource;
  @Prop({default: false}) private circle!: boolean;
  @Prop({default: false}) private showManageLink!: boolean;
  private iconMenu = mdiMenuDown;
  private iconDots = mdiDotsVertical;
  private routeSurveyEmbed = Route.SurveysEmbed;
  private saveTemplateDialogShown = false;
  private sendInvitationsConfirmDialogShown = false;
  private deleteSurveyDialogShown = false;
  private isOpen = isOpen;
  private snackbar: Snackbar = {visible: false, message: ''};

  closeSaveTemplateDialog() {
    this.saveTemplateDialogShown = false;
  }

  closeDeleteSurveyDialog() {
    this.deleteSurveyDialogShown = false;
  }

  closeSendInvitationsConfirmDialog(message: string) {
    this.sendInvitationsConfirmDialogShown = false;
    this.snackbar.visible = true;
    this.snackbar.message = message;
  }
}
