

























import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {dateFormat} from '@/filters';
import {isOpen, isTargeted} from '@/utility';

@Component
export default class SurveySentStatusChip extends Vue {
  @Prop() private sentFlag!: boolean;
  @Prop() private sentDate!: Date;
  @Prop() private type!: string;
  private dateFormat = dateFormat;
  private isOpen = isOpen;
  private isTargeted = isTargeted;
}
