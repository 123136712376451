




















import Vue from 'vue';
import Component from 'vue-class-component';
import {AlertMessage} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import {surveyModule} from '@/store/modules';
import DialogAlert from '@/components/ui/DialogAlert.vue';
import DialogActions from '@/components/ui/DialogActions.vue';
import Dialog from '@/components/ui/Dialog.vue';
import {sleep} from '@/utility';

@Component({
  components: {
    Dialog,
    DialogActions,
    DialogAlert,
  },
})
export default class SendInvitationsConfirmDialog extends Vue {
  @Prop() surveyId!: string;
  private alert: AlertMessage | null = null;
  private sending = false;

  get busy(): boolean {
    return surveyModule.busy;
  }

  get saving(): boolean {
    return surveyModule.saving;
  }

  async cancel() {
    this.$emit('close');
  }

  async send() {
    this.sending = true;
    if (process.env.NODE_ENV === 'development') {
      await sleep(2000);
    }

    try {
      await surveyModule.action({
        attributes: {
          action: 'send',
          surveyId: this.surveyId,
        },
      });
      const survey = surveyModule.find(this.surveyId);
      survey.attributes.sentDate = new Date();
      survey.attributes.sentFlag = true;
      this.$emit('close', 'Survey invitations queued for sending');
    } catch (err) {
      console.error(err);
      const error = err.response?.data?.errors[0]?.detail;
      this.alert = {
        type: 'error',
        message: `Error: ${error ? error : 'Could not send invitation'}`,
      };
    } finally {
      this.sending = false;
    }
  }
}
