




















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {displayControlModule} from '@/store/modules';

@Component
export default class DisplayControl extends Vue {
  @Prop({default: false}) private disabled!: boolean;
  //@Prop({default: []}) private items!: SurveyFieldItemResourcePartial[];
  @Prop() private label!: string;
  @Prop({default: false}) private readOnly!: boolean;
  @Prop() private name!: string;
  @Prop() private placeholder!: string;
  @Prop({default: false}) private sampleValue!: boolean;
  @Prop() private helpText!: string;
  @Prop({default: false}) private required!: boolean;
  @Prop() private value!: string;
  @Prop({default: false}) private persistentHint!: boolean;
  @Prop() id!: string;
  @Prop({default: null}) input!: () => {} | null;
  private headers = [
    {text: 'Sample Date', value: 'date'},
    {text: 'Sample Full Time', value: 'fullTime'},
    {text: 'Sample Part Time', value: 'partTime'},
  ];
  private items = [
    {date: '2021-01-02', fullTime: 10, partTime: 2},
    {date: '2021-04-22', fullTime: 12, partTime: 3},
  ];

  async created(): Promise<void> {
    console.log('cccccc');
    await displayControlModule.loadMulti();
  }
}
