











import Vue from 'vue';
import Component from 'vue-class-component';
import {mdiInformationOutline} from '@mdi/js';

@Component
export default class InfoTooltip extends Vue {
  private iconInfo = mdiInformationOutline;
}
