



































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {isOpen, isTargeted} from '@/utility';

@Component
export default class SurveyStatusChip extends Vue {
  @Prop() private published!: boolean;
  @Prop() private loading!: boolean;
  @Prop() private type!: string;
  private isOpen = isOpen;
  private isTargeted = isTargeted;
}
