























import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import RequiredLabel from '@/components/ui/RequiredLabel.vue';
import {dropdownChainModule} from '@/store/modules';
import {SelectItem} from '@/interfaces';
import {InputValidationRules} from 'vuetify';

@Component({components: {RequiredLabel}})
export default class DropdownChainAutocomplete extends Vue {
  @Prop() value!: string;
  @Prop({default: false}) required!: boolean;
  @Prop({default: () => []}) rules!: InputValidationRules;

  get list(): SelectItem[] {
    return dropdownChainModule.list
      .filter(item => item.attributes.parentId === null)
      .map(item => ({
        text: item.attributes.label,
        value: item.id,
      }));
  }

  get listing(): boolean {
    return dropdownChainModule.listing;
  }

  async created(): Promise<void> {
    await dropdownChainModule.loadMulti();
  }

  update(value: string): void {
    this.$emit('input', value);
  }
}
