
































import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {SurveyType} from '@/enums';
import {surveyModule} from '@/store/modules';
import {Snackbar, SurveyResource} from '@/interfaces';
import NotificationBar from '@/components/ui/NotificationBar.vue';
@Component({
  components: {NotificationBar},
})
export default class ButtonSurveyPublish extends Vue {
  @Prop() private type!: SurveyType;
  @Prop() private template!: boolean;
  @Prop() private published!: boolean;
  @Prop() private sent!: boolean;
  @Prop() private surveyId!: string;
  @Prop({default: false}) private disabled!: boolean;
  @Prop({default: false}) private loading!: boolean;
  private snackbar: Snackbar = {visible: false, message: ''};

  get saving(): boolean {
    return surveyModule.saving;
  }

  get survey(): SurveyResource {
    return surveyModule.find(this.surveyId);
  }

  async save(value: boolean) {
    this.survey.attributes.published = value;
    try {
      await surveyModule.save(this.surveyId);
      this.snackbar.message = `Your survey is now ${
        this.published ? 'enabled' : 'disabled'
      }`;
      this.snackbar.visible = true;
    } catch (err) {
      console.error(err);
      const error = err.response?.data?.errors[0]?.detail;
      this.snackbar.message = `Error: ${error ? error : ''}`;
      this.snackbar.visible = true;
    }
  }
}
