var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table-row@5"}}):_vm._e(),(!_vm.loading)?_c('div',[_c('v-list',[_c('draggable',{attrs:{"group":"fields"},on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false},"change":_vm.pageMoved},model:{value:(_vm.surveyFields),callback:function ($$v) {_vm.surveyFields=$$v},expression:"surveyFields"}},[_vm._l((_vm.surveyFields),function(field){return _c('div',{key:field.id},[(field.attributes.questionCollectionId)?_c('v-list-item',{style:({backgroundColor: '#efefef'})},[_c('v-list-item-action',{staticClass:"drag"},[_c('v-icon',[_vm._v(_vm._s(_vm.iconDrag))])],1),_c('v-list',{style:({backgroundColor: '#efefef'})},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',[_vm._v("QUESTION COLLECTION:")]),_c('strong',[_vm._v(_vm._s(field.attributes.questionCollection.label))]),_c('IconQuestionCollectionRepeatable',{attrs:{"show":Boolean(field.attributes.questionCollection.repeatable)}}),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.iconMore))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"disabled":_vm.busy && _vm.fieldId === String(field.id),"loading":_vm.deletingIds.includes(field.id) ||
                              (_vm.deleting && _vm.fieldId === String(field.id))},on:{"click":function($event){return _vm.deleteAllFieldsInQuestionCollection(
                              field.attributes.questionCollectionId
                            )}}},[_c('v-icon',[_vm._v(_vm._s(_vm.iconDelete))]),_vm._v(" Delete Question Collection")],1)],1)],1),_c('v-list-item',{attrs:{"link":""}},[_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"disabled":_vm.busy && _vm.fieldId === String(field.id),"loading":_vm.deletingIds.includes(field.id) ||
                              (_vm.deleting && _vm.fieldId === String(field.id))},on:{"click":function($event){return _vm.restoreQuestionCollection(
                              field.attributes.questionCollectionId
                            )}}},[_c('v-icon',[_vm._v(_vm._s(_vm.iconRestore))]),_vm._v(" Restore deleted fields")],1)],1)],1)],1)],1)],1),_vm._l((_vm.getSurveyFieldsInQuestionCollection(
                  String(field.attributes.questionCollectionId)
                )),function(field){return _c('v-list-item',{key:field.id},[_c('v-list-item-content',[_c('SurveyField',{attrs:{"dropdownChainId":field.attributes.dropdownChainId,"displayControlId":field.attributes.displayControlId,"items":field.attributes.items,"label":field.attributes.label,"name":field.attributes.name,"disabled":false,"sampleValue":false,"validation":field.attributes.validation,"helpText":field.attributes.helpText,"persistentHint":true,"type":field.attributes.type,"value":field.attributes.value}}),_c('p',[_vm._v(" Field Name: "),_c('code',[_vm._v(_vm._s(field.attributes.name))]),_c('IconSurveyFieldJump',{attrs:{"surveyField":field}}),_c('IconSurveyFieldRepeatable',{attrs:{"surveyField":field}})],1),(field.attributes.questionId)?_c('small',[_c('em',[_vm._v("This field is part of the question collection: "),_c('strong',[_vm._v(_vm._s(field.attributes.questionCollection.label))])])]):_vm._e()],1),_c('v-list-item-action',[_c('ButtonEditAction',{attrs:{"disabled":_vm.busy && _vm.fieldId === String(field.id),"loading":_vm.getting && _vm.fieldId === String(field.id)},on:{"click":function($event){_vm.showSurveyFieldDialog(String(field.id))}}})],1),_c('v-list-item-action',[(
                      !field.attributes.question ||
                        (field.attributes.question &&
                          field.attributes.question.validation &&
                          !field.attributes.question.validation.required)
                    )?_c('ButtonDeleteAction',{attrs:{"disabled":_vm.busy && _vm.fieldId === String(field.id),"loading":_vm.deletingIds.includes(field.id)},on:{"click":function($event){_vm.deleteField(String(field.id))}}}):_c('ButtonDeleteAction',{attrs:{"disabled":true}})],1)],1)})],2)],1):_c('v-list-item',[(!field.attributes.questionId)?_c('v-list-item-action',{staticClass:"drag"},[_c('v-icon',[_vm._v(_vm._s(_vm.iconDrag))])],1):_c('v-list-item-action'),_c('v-list-item-content',[_c('SurveyField',{attrs:{"dropdownChainId":field.attributes.dropdownChainId,"displayControlId":field.attributes.displayControlId,"items":field.attributes.items,"label":field.attributes.label,"name":field.attributes.name,"disabled":false,"sampleValue":false,"validation":field.attributes.validation,"helpText":field.attributes.helpText,"persistentHint":true,"type":field.attributes.type,"value":field.attributes.value}}),_c('p',[_vm._v(" Field Name: "),_c('code',[_vm._v(_vm._s(field.attributes.name))]),_c('IconSurveyFieldJump',{attrs:{"surveyField":field}}),_c('IconSurveyFieldRepeatable',{attrs:{"surveyField":field}})],1)],1),_c('v-list-item-action',[_c('ButtonEditAction',{attrs:{"disabled":_vm.busy && _vm.fieldId === String(field.id),"loading":_vm.getting && _vm.fieldId === String(field.id)},on:{"click":function($event){_vm.showSurveyFieldDialog(String(field.id))}}})],1),_c('v-list-item-action',[(
                  !field.attributes.question ||
                    (field.attributes.question &&
                      field.attributes.question.validation &&
                      !field.attributes.question.validation.required)
                )?_c('ButtonDeleteAction',{attrs:{"disabled":_vm.busy && _vm.fieldId === String(field.id),"loading":_vm.deletingIds.includes(field.id)},on:{"click":function($event){_vm.deleteField(String(field.id))}}}):_c('ButtonDeleteAction',{attrs:{"disabled":true}})],1)],1)],1)}),(_vm.surveyFields.length === 0)?_c('v-list-item',[_c('v-list-item-content',[_c('em',{staticClass:"text-center muted"},[_vm._v("No fields")])])],1):_vm._e()],2)],1)],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mr-1",attrs:{"color":"accent","disabled":_vm.saving && _vm.fieldId === 'new',"loading":_vm.saving && _vm.fieldId === 'new'},on:{"click":function($event){return _vm.showSurveyFieldDialog('new')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.iconAdd))]),_vm._v(" Add field")],1),(_vm.isIntegrated(_vm.survey.attributes.type))?_c('v-btn',{attrs:{"color":"accent","disabled":_vm.saving && _vm.fieldId === 'new',"loading":_vm.saving && _vm.fieldId === 'new'},on:{"click":function($event){return _vm.showQuestionCollectionAddToSurveyDialog('new')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.iconAdd))]),_vm._v(" Add question collection")],1):_vm._e()],1):_vm._e(),(_vm.surveyFieldDialogShown)?_c('SurveyFieldDialog',{attrs:{"fieldId":_vm.fieldId,"newItem":_vm.newField,"surveyId":_vm.surveyId},on:{"close":_vm.closeSurveyFieldDialog}}):_vm._e(),(_vm.questionCollectionAddToSurveyDialogShown)?_c('QuestionCollectionAddToSurveyDialog',{attrs:{"fieldId":_vm.fieldId,"newItem":_vm.newField,"surveyPageId":_vm.pageId,"surveyId":_vm.surveyId},on:{"close":_vm.closeQuestionCollectionAddToSurveyDialog}}):_vm._e(),_c('NotificationBar',{attrs:{"snackbar":_vm.snackbar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }