























































































































































































































































































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {
  EditableFieldSaveEvent,
  SelectItem,
  SurveyResource,
  UserGroupResource,
  ProductResource,
} from '@/interfaces';
import {Prop} from 'vue-property-decorator';
import CreatedModifiedCell from '@/components/ui/CreatedModifiedCell.vue';
import {surveyModule, userGroupModule, productModule} from '@/store/modules';
import EditableField from '@/components/ui/EditableField.vue';
import SurveyType from '@/components/ui/SurveyType.vue';
import {
  mdiApplicationCog,
  mdiContentCopy,
  mdiFileDocumentEdit,
  mdiFileDocumentEditOutline,
  mdiImageEdit,
} from '@mdi/js';
import {QuestionType} from '@/enums';
import DatePicker from '@/components/surveys/DatePicker.vue';
import {format} from 'date-fns-tz';
import Wysiwyg from '@/components/ui/Wysiwyg.vue';
import DateTimePicker from '@/components/surveys/DateTimePicker.vue';
import {assetApi} from '@/api';
import SurveyStatusChip from '@/components/ui/SurveyStatusChip.vue';
import SurveySentStatusChip from '@/components/ui/SurveySentStatusChip.vue';
import InfoTooltip from '@/components/ui/InfoTooltip.vue';
import {rulesMultipleEmailsNotRequired} from '@/utility';

@Component({
  components: {
    InfoTooltip,
    SurveySentStatusChip,
    SurveyStatusChip,
    DateTimePicker,
    Wysiwyg,
    DatePicker,
    SurveyType,
    EditableField,
    CreatedModifiedCell,
  },
})
export default class SurveyDetail extends Vue {
  @Prop() private surveyId!: string;
  private activePanel: number[] = [0];
  private savingId = '';
  private format = format;
  private showAppearance = false;
  private fields = [
    {
      label: 'Name',
      key: 'name',
      editable: true,
      infoTooltip: 'What is the name of this survey',
    },
    {
      label: 'Expires',
      key: 'expires',
      editable: true,
      type: QuestionType.DateTime,
      infoTooltip: 'Survey end date',
    },
    {
      label: 'Notify',
      key: 'notify',
      editable: true,
      hint: 'Multiple addresses can be separated by commas',
      infoTooltip:
        'Who in Communitech should be notified when Survey has received a response',
      rules: rulesMultipleEmailsNotRequired,
    },

    {
      label: 'Type',
      key: 'type',
      editable: false,
      infoTooltip:
        'Highlights the type of survey you are sending out (integrated, non-integrated, open, targeted)',
    },
    {
      label: 'URL',
      key: 'url',
      editable: false,
      clipboard: true,
      infoTooltip: 'Survey URL',
    },
    {
      label: 'UTM Parameters',
      key: 'utm',
      editable: true,
      hint:
        'UTM codes or any other data that you want appended after the ? in the survey URL',
      infoTooltip:
        'UTM codes or any other data that you want appended after the ? in the survey URL',
    },
  ];
  private questionType = QuestionType;
  private iconCopy = mdiContentCopy;
  private iconAppearance = mdiImageEdit;
  private iconOverview = mdiFileDocumentEdit;
  private iconOther = mdiApplicationCog;
  private iconThankYouMessage = mdiFileDocumentEditOutline;

  get getting(): boolean {
    return surveyModule.getting;
  }

  get saving(): boolean {
    return surveyModule.saving;
  }

  get survey(): SurveyResource {
    return surveyModule.find(this.surveyId);
  }

  get loadingUserGroups(): boolean {
    return userGroupModule.loading;
  }

  get loadingProducts(): boolean {
    return productModule.loading;
  }

  get userGroups(): SelectItem[] {
    return userGroupModule.list.length > 0
      ? userGroupModule.list.map((userGroup: UserGroupResource) => {
          return {
            text: userGroup.attributes.label,
            value: userGroup.attributes.id,
          };
        })
      : [];
  }

  get products(): SelectItem[] {
    return productModule.list.length > 0
      ? productModule.list.map((product: ProductResource) => {
          return {
            text: product.attributes.formAlias,
            value: product.attributes.dwProductId,
          };
        })
      : [];
  }

  // get productsMap(): Record<string, string> {
  //   if (productModule.list.length === 0) {
  //     return {};
  //   }
  //   const map = {};
  //   productModule.list.forEach((product: ProductResource) => {
  //     map[String(product.attributes.dwProductId)] =
  //       product.attributes.formAlias;
  //   });
  //   return map;
  // }

  async created() {
    try {
      if (!this.survey) {
        await surveyModule.loadById(this.surveyId);
      }
      await userGroupModule.loadMulti();
    } catch (err) {
      console.error(err.response.data);
    }
    try {
      if (!this.survey) {
        await surveyModule.loadById(this.surveyId);
      }
      await productModule.loadMulti();
    } catch (err) {
      console.error(err.response.data);
    }
  }

  async save({id}: EditableFieldSaveEvent): Promise<void> {
    // in this case the id is not a record id and just an identifier for the
    // loading indicator
    console.log('save', id);
    this.savingId = id;
    await surveyModule.save(this.surveyId);
  }

  async publish(): Promise<void> {
    //console.log('save', id);
    //this.savingId = id;
    await surveyModule.save(this.surveyId);
  }

  async uploadFile(event: EditableFieldSaveEvent): Promise<void> {
    // value is File[];
    // let x: File;
    const file = event.value as File;
    console.log('save', file);

    const data = await assetApi.postFile(file);
    console.log('event.id', event.id);
    this.survey.attributes[event.id] = data.attributes.url;
    console.log('data.attributes.url', data.attributes.url);
    console.log('save', event.id);
    this.savingId = event.id;
    await surveyModule.save(this.surveyId);
  }
}
