







































































import Vue from 'vue';
import Component from 'vue-class-component';
import {mdiCalendar, mdiClose} from '@mdi/js';
import {Prop} from 'vue-property-decorator';

@Component
export default class DateRangeQuestion extends Vue {
  @Prop({default: ''}) dateStart!: string;
  @Prop({default: ''}) dateEnd!: string;
  private iconCalendar = mdiCalendar;
  private iconClear = mdiClose;
  private menuDateStart = false;
  private menuDateEnd = false;
}
