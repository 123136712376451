














import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {SurveyFieldResource} from '@/interfaces';
import {mdiDebugStepOver} from '@mdi/js';

@Component
export default class IconSurveyFieldJump extends Vue {
  @Prop() private surveyField!: SurveyFieldResource;
  private iconJump = mdiDebugStepOver;

  hasJump(): boolean {
    if (this.surveyField.attributes.items.length > 0) {
      const jumps = this.surveyField.attributes.items.filter(
        item => item.jumpToPageId
      );
      return jumps.length > 0;
    }
    return false;
  }
}
