






import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {AlertMessage} from '@/interfaces';

@Component
export default class DialogAlertDense extends Vue {
  @Prop() private alert!: AlertMessage;
}
