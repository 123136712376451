















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {Route} from '@/enums';

@Component
export default class ButtonSurveyEmbed extends Vue {
  @Prop() private surveyId!: string;
  @Prop({default: ''}) private tooltip!: string;
  private routeSurveyEmbed = Route.SurveysEmbed;
}
