


















import Vue from 'vue';
import Component from 'vue-class-component';
import SurveyActions from '@/components/surveys-edit/SurveyActions.vue';
import SurveyDetail from '@/components/surveys-edit/SurveyDetail.vue';
import SurveyPages from '@/components/surveys-edit/SurveyPages.vue';
import PageTitle from '@/components/ui/PageTitle.vue';
import Page from '@/components/ui/Page.vue';
import {surveyFieldModule, surveyModule} from '@/store/modules';
import {surveyFieldItemModule} from '@/store/modules/surveyFieldItemModule';
import SurveyNavigation from '@/components/surveys-edit/SurveyNavigation.vue';
import {SurveyResource} from '@/interfaces';
import Spinner from '@/components/ui/Spinner.vue';
import {Route} from '@/enums';

@Component({
  components: {
    Spinner,
    SurveyNavigation,
    Page,
    PageTitle,
    SurveyActions,
    SurveyDetail,
    SurveyPages,
  },
})
export default class SurveyEdit extends Vue {
  private route = Route;

  get surveyId(): string {
    return this.$route.params.id;
  }

  get survey(): SurveyResource {
    return surveyModule.find(this.surveyId);
  }

  get loading(): boolean {
    return surveyModule.loading;
  }

  async created(): Promise<void> {
    surveyModule.reset();
    surveyFieldModule.reset();
    surveyFieldItemModule.reset();
    try {
      await surveyModule.loadById(this.surveyId);
    } catch (err) {
      console.error(err.response.data);
    }
  }
}
